<template>
  <section ref="m_common" :class="`m_common detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar :title="domainConfig['IAmTitle']" :logo="domainConfig['icon']" :deviceType="deviceType"
      @go-back="goback" />
    <slot />
    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import HeadBar from '@/pages/components/back_head.vue';
import FootBar from '@/pages/components/footer.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    HeadBar,
    FootBar
  },

  computed: {
    ...mapGetters([
      'deviceType',
      'host',
      "domainConfig"
    ])
  },
  methods: {
    goback () {
      this.$router.go(-1);
    }
  },

};
</script>